
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import PeopleFind from "../../../components/People/people-find.vue";
import editmotivoDevolucion from "./edit-motivoDevolucion.vue";
class PageMotivosDevolucionRequest extends PageRequest {

  motivo: string
  descripcion: string
}
@Component({
  components: {    
    PeopleFind,
    editmotivoDevolucion
  }
})

export default class MotivoDevolucion extends AbpBase{
  pagerequest: PageMotivosDevolucionRequest = new PageMotivosDevolucionRequest()
  creationTime: Date[] = []
  createModalShow: boolean = false
  editModalShow: boolean = false
  pagination: any = {}
  findModalShow: boolean = false

 get list() {
     
    return this.$store.state.motivoDevolucion.list
  }
  get loading() {
    return this.$store.state.motivoDevolucion.loading
  }
    get pageSize() {
    return this.$store.state.motivoDevolucion.pageSize
  }
  get totalCount() {
    return this.$store.state.motivoDevolucion.totalCount
  }
  get currentPage() {
    return this.$store.state.motivoDevolucion.currentPage
  }
  set currentPage(page) {
    this.$store.commit('motivoDevolucion/setCurrentPage', page)
    this.getpage()
  }
  async find() {
    this.findModalShow = true
  }
  async create() {
    this.createModalShow = true
    this.pagerequest = new PageMotivosDevolucionRequest()
  }
   editRow(item, index, button) {
     
    this.$store.commit('motivoDevolucion/edit', item)
    this.edit()
  }
   deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('Deseas Eliminar este Motivo de Devolucion'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'motivoDevolucion/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    
    await this.getpage()
    this.findModalShow = false
  }

  async created() {
    this.getpage()
  }
  async getpage() {
    
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'motivoDevolucion/getAll',
      data: this.pagerequest
    })
    const pagination = { ...this.pagination }
    pagination.total = this.$store.state.motivoDevolucion.totalCount
    pagination.pageSize = this.$store.state.motivoDevolucion.pageSize
    pagination.current = this.$store.state.motivoDevolucion.currentPage
    this.pagination = pagination
  }
  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('motivoDevolucion/setCurrentPage', pagination.current)
    this.getpage()

    await this.getpage()
  }
   edit() {
    this.editModalShow = true
  }
  hide() {
    this.findModalShow = false
  }

  columns = [
    {
      title: this.L('Motivo'),
      dataIndex: 'motivo',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Descripcion'),
      dataIndex: 'descripcion',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Fecha Creacion'),
      dataIndex: 'creationTime',
      scopedSlots: { customRender: "date" },
    },
   {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ];
  searchDefinition: {}[] = [
    {
      fieldName: 'Motivo',
      fieldType: 'text',
      fieldBind: 'Motivo',
      value: ''
    },
    {
      fieldName: 'Descripcion',
      fieldType: 'text',
      fieldBind: 'Descripcion',
      value: ''
    },
  
  ]
}



