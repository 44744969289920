
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import MotivoDevolucion from '../../../store/entities/OZONE/motivoDevolucion'
@Component({
  components: { Modal, BaseButton, BaseCheckbox }
})
export default class EditMotivoDevolucion extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  motivoDevolucion: MotivoDevolucion = new MotivoDevolucion()
  title: String = ''
  createModalShow: boolean = false
  editModalShow: boolean = false
  disabled: boolean = false

  created() {}
  async save() {
    
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: 'motivoDevolucion/update',
          data: this.motivoDevolucion
        })
      } else {
        await this.$store.dispatch({
          type: 'motivoDevolucion/create',
          data: this.motivoDevolucion
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    if (this.editing) {
        
      this.motivoDevolucion = Util.extend(true, {}, this.$store.state.motivoDevolucion.editMotivoDevolucion)
    }
    if (this.editing) {
      this.title = 'Editar Mótivo de Devolución'
    } else {
      this.title = 'Añadir Mótivo de Devolución'
    }
    
  }
  checkFormValidity() {
    const valid = (this.$refs.motivoDevolucionForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.motivoDevolucion = new MotivoDevolucion()
  }
  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.CuentaEmisora.Edit', 'OZONE.CuentaEmisora.Create', 'OZONE.CuentaEmisora.Delete')
  }
  motivoDevolucionRules = {
    motivo: {
      required: { required: true, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Motivo')),
      trigger: 'blur'
    },
    descripcion: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Descripcion')),
      trigger: 'blur'
    
     }
  }
}
